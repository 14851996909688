import { Tabs } from "antd";
import React from "react";
import Product from "../../../global/components/Icons/Product";
import Features from "../../../global/components/Icons/Features";
import Setting from "../../../global/components/Icons/Setting";
import interk from "../../../assets/product/intertek.png";
import browser from "../../../assets/product/browser.png";
import cloud from "../../../assets/product/cloud-outline.png";
import doller from "../../../assets/product/doller.png";
import galaSecure from "../../../assets/product/gala_secure.png";
import multipleUser from "../../../assets/product/multipleuser.png";
import ndaa from "../../../assets/product/ndaa.png";
import solar from "../../../assets/product/solar.png";
const ProductTab = ({ product }) => {
  const tabItems = [
    {
      key: "1",
      label: "Product",
      icon: <Product />, // Apple icon
      children: (
        <div>
          <p className="text-[#51525B] font-[Poppins] text-[13px] text-justify mb-[10px]">
            {product?.Product.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: "Features",
      icon: <Features />,
      children: (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-[30px] lg:gap-[100px] md:gap-[50px] my-[30px]">
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={interk}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              UL 294 Listed
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={browser}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Browser Managed
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={ndaa}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              NDAA Compliant
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={solar}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Scalability
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={cloud}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] text-center font-medium">
              Cloud Management
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={galaSecure}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Secure
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={doller}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-center text-[16px] font-medium">
              Low total cost of ownership
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={multipleUser}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-center text-[16px] font-medium">
              Multiple User Privileges
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Specification",
      icon: <Setting />,
      children: (
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div class="specification-container">
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Model
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Model}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Technology
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Technology}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Frequency
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Frequency}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Mounting
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Mounting}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Dimensions
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Dimensions}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                OEM Label Area Dimensions
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.AreaDimensions}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Weight
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Weight}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Certifications
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Certifications}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                IP Code2
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.IpCode2}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Voltage3
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Voltage3}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Current Draw
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.CurrentDraw}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Read Range4
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.ReadRange4}
              </span>
            </div>
          </div>

          <div class="specification-container">
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Cabling5
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Cabling5}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Interface
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Interface}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Operating Temperature
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.OperatingTemperature}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Color Options
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.ColorOptions}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Audio Tone
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.AudioTone}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Indoor & Outdoor Installation
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.IndoorOutdoorInstallation}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Warranty
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Warranty}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                LED
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.LED}
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Technologies Supported
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                {product?.Specification?.Technologies}
              </span>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="my-tab px-[10px] lg:px-[50px] 2xl:px-[180px] xl:px-[180px]">
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default ProductTab;
