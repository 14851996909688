import React, { useState } from "react";
import Filter from "./Filter";
import ProductCardWrapper from "./ProductCardWrapper";
import { Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ProductsAndFilter = () => {
  const [pageNo, setPageNo] = useState(1);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 custom-padding">
      <div className="col-span-1">
        <Filter />
      </div>
      <div className="col-span-1 lg:col-span-3">
        <ProductCardWrapper pageNo={pageNo} />
        <Pagination
          className="mt-4 mb-16"
          align="end"
          total={20}
          onChange={(e) => setPageNo(e)}
          prevIcon={<LeftOutlined />}
          nextIcon={<RightOutlined />}
        />
      </div>
    </div>
  );
};

export default ProductsAndFilter;
