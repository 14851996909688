import { Divider } from "antd";
import React from "react";

const Description = ({ product }) => {
  return (
    <div className="px-[10px] lg:px-[50px] 2xl:px-[180px] xl:px-[180px] mb-[56px]">
      <Divider />
      <h2 className="text-[#121212] text-[16px] font-semibold mb-[16px]">
        Description
      </h2>
      <p className="text-[#51525B] text-[13px] text-justify">
        {product?.Description}
      </p>
    </div>
  );
};

export default Description;
