import React from "react";

const BulletPointIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={4} height={4} fill="none">
      <circle cx={2} cy={2} r={2} fill="#000" />
    </svg>
  );
};

export default BulletPointIcon;
