import React from "react";
const ProductView = ({ product }) => {
  return (
    <div className="flex flex-col  md:w-[380px] lg:w-[400px]">
      <div className="large-img-box w-full h-[359px] md:h-[380px] lg:h-[504px] border rounded-md mb-[16px]">
        <img
          src={product?.Image}
          alt=""
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      <div className="mini flex cursor-pointer gap-[10px]">
        <div className="mini-img-box w-[78px] h-[83px] md:w-[83px] md:h-[89px] lg:w-[114px] lg:h-[112px] border border-[#1E42B8] rounded-md">
          <img
            src={product?.Image}
            alt=""
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        <div className="mini-img-box w-[78px] h-[83px] md:w-[83px] md:h-[89px] lg:w-[114px] lg:h-[112px]">
          <img
            src={product?.Image}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="mini-img-box w-[78px] h-[83px] md:w-[83px] md:h-[89px] lg:w-[114px] lg:h-[112px]">
          <img
            src={product?.Image}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="mini-img-box w-[78px] h-[83px] md:w-[83px] md:h-[89px] lg:w-[114px] lg:h-[112px]">
          <img
            src={product?.Image}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductView;
