import React, { useEffect, useState } from "react";
import "../Style/productDetails.css";
import ProductTopWrapper from "../Components/ProductTopWrapper";
import Description from "../Components/Description";
import ProductTab from "../Components/ProductTab";
import { useParams } from "react-router-dom";
import { productDetails } from "../../../global/utils/constants/productDetails";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  // Fetch product data from a mock or API endpoint
  useEffect(() => {
    const fetchData = () => {
      const data = productDetails.find(
        (product) => product.id === parseInt(id, 10) // Ensure `id` is parsed as a number
      );
      setProduct(data);
    };

    fetchData();
  }, [id]);

  console.log({ product });

  return (
    <div>
      <ProductTopWrapper product={product} />
      <Description product={product} />
      <ProductTab product={product} />
    </div>
  );
};

export default ProductDetails;
