import React from "react";
import ProductView from "./ProductView";
import ProductInfo from "./ProductInfo";
import PurchaseProduct from "./PurchaseProduct";

const ProductTopWrapper = ({ product }) => {
  return (
    <div className="flex flex-col md:flex-row custom-padding pt-[110px] md:pt-[150px] gap-[16px]">
      <div>
        <ProductView product={product} />
      </div>
      <div className="flex gap-[16px] flex-col md:flex-col lg:flex-row">
        <ProductInfo product={product} />
        <PurchaseProduct product={product} />
      </div>
    </div>
  );
};

export default ProductTopWrapper;
