import { Button, Divider } from "antd";
import React, { useState } from "react";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import BuyNow from "../../../global/components/Icons/BuyNow";
import Cart from "../../../global/components/Icons/Cart";
import Heart from "../../../global/components/Icons/Heart";
import Compare from "../../../global/components/Icons/Compare";

const Quantity = ({ product }) => {
  const [quantity, setQuantity] = useState(1);
  const price = product?.Price;
  return (
    <div className="px-[16px] py-[24px] border border-[#EEEEEF] shadow-sm rounded-lg w-full">
      <h4 className="text-[#121212] text-[16px] font-semibold mb-[16px]">
        Enter Quantity
      </h4>
      <div className="quantity-amount flex justify-between items-center">
        <p className="text-[#51525B] text-[13px] font-medium flex">
          Quantity :{" "}
          <span className="text-[#080808] font-semibold ml-[8px]">
            {quantity}
          </span>
        </p>
        <div class="quantity-input">
          <button
            class="quantity-btn minus"
            onClick={() => setQuantity(quantity - 1)}
          >
            <span>
              <FaMinus color="#74757C" size={14} />
            </span>
          </button>
          <input type="number" class="quantity-field" value={quantity} />
          <button
            class="quantity-btn plus"
            onClick={() => setQuantity(quantity + 1)}
          >
            <span>
              <FaPlus color="#74757C" size={14} />
            </span>
          </button>
        </div>
      </div>
      <Divider />
      <div className="total flex justify-between items-center mb-[24px]">
        <p className="text-[13px] font-medium text-[#51525B]">Total : </p>
        <p className="text-[#121212] text-[16px] font-semibold">
          ${price * quantity}
        </p>
      </div>
      <div className="btn-wrapper mb-[32px]">
        <Button className="bg-[#1E42B8] text-[14px] font-medium leading-[20px] text-[#FEFFFF] hover:!bg-[#1E42B8] hover:!text-white border-none w-full mb-[8px] h-[40px]">
          <BuyNow /> Buy Now
        </Button>
        <Button className="w-full border border-[#4B68C6] h-[40px] text-[#3A3A41] text-[14px] font-medium leading-[20px]">
          <Cart /> Add To Cart
        </Button>
      </div>
      <div className="fav-com flex justify-between items-center">
        <p className="text-[#3A3A41] text-[16px] font-medium flex">
          <Heart />
          Favourite
        </p>
        <Divider type="vertical" />
        <p className="text-[#3A3A41] text-[16px] font-medium flex">
          <Compare />
          Compare
        </p>
      </div>
    </div>
  );
};

export default Quantity;
