import product1 from "../../../assets/product/product1.png";
import product2 from "../../../assets/product/product2.png";
import product3 from "../../../assets/product/product3.png";
import product4 from "../../../assets/product/product4.png";
import product5 from "../../../assets/product/product5.png";
import product6 from "../../../assets/product/product6.png";
import product7 from "../../../assets/product/product7.png";
import product8 from "../../../assets/product/product8.png";
import product9 from "../../../assets/product/product9.png";
import product10 from "../../../assets/product/product10.png";
import product11 from "../../../assets/product/product11.png";
import product12 from "../../../assets/product/product12.png";
import product13 from "../../../assets/product/product13.png";
import product14 from "../../../assets/product/product14.png";
import product15 from "../../../assets/product/product15.png";
import product16 from "../../../assets/product/product16.png";
import product17 from "../../../assets/product/product17.png";
import product18 from "../../../assets/product/product18.png";
import product19 from "../../../assets/product/product19.png";
import product23 from "../../../assets/product/product23.png";

export const productDetails = [
  {
    id: 1,
    Title: "PATAGONIA PROXIMITY READER & KEYPAD",
    ShortDecription:
      "Pyramid Series Proximity® from Farpointe Data sets the electronic security benchmark for 125-kHz.",
    KeyFeatures: [
      "Unique Security Features",
      "Energy Conservation Technology",
      "Read Range Boosting Technology",
      "Lifetime Warrantee",
    ],
    Price: 500,
    Description:
      "Pyramid Series Proximity® from Farpointe Data sets the electronic security benchmark for 125-kHz proximity readers, cards, and tags. Based upon proven contactless digital radio frequency identification (RFID) technology, Pyramid readers interface with a wide range of electronic access control systems by complying with the Wiegand communication protocol. They offer value-add features such as MAXSecure™ and fleaPower™, and can be ordered to support several proximity card and tag technologies. Additionally, Pyramid cards and tags are passive devices, eliminate maintenance by requiring no battery, and can be ordered to support several proximity reader technologies.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    \n Browser Managed provides the greatest flexibility for programming. Use any smart device with a browser and have concurrent administrators with out having to load or manage other software. Eliminate the requirement to manage software and its compatibility!
    \n Our "Hybrid" design allows the system to be managed on premise or via the cloud with the same browser. Our key benefits are you own your data, you own the software and will function with no compromise of features if the cloud is not functioning.
    \n Our system includes a mobile application. This application provides a basic functions of managing your system, looking and unlocking of your doors as well as viewing events with video.`,
    Specification: {
      Model: "P-500",
      Technology: "Proximity",
      Frequency: "125 kHz",
      Mounting:
        "Metal or plastic US single-gang wall box, as well as flat surfaces",
      Dimensions: `1.7" W * 3.2" H * 0.7" D(43 mm * 81 mm * 18 mm`,
      AreaDimensions: `0.9" W 0.35" H (22.86 mm 8.89 mm) with corner radius of 0.06" (1.5 mm)`,
      Weight: "3 oz (85 g)",
      Certifications: "FCC, ICC, CE, C-Tick, UL Standard 2941",
      IpCode2: "IP67",
      Voltage3: "+5 - 16 VDC",
      CurrentDraw: "35 mA typical, 75 mA peak @ 12 VDC",
      ReadRange4: "Up to 8 inches (202 mm)",
      Cabling5: `24 AWG minimum, multiconductor stranded with an overall foil shield`,
      Interface: `Wiegand (26-bit and custom formats), ABA Track II magnetic stripe (clock and data) or OSDP6`,
      OperatingTemperature: `–40° F to 149° F (–40° C to +65° C)`,
      ColorOptions: `Black and a white snap-on cover included standard`,
      AudioTone: "Beeper included standard",
      IndoorOutdoorInstallation:
        "Electronics sealed in weather- and tamper-resistant epoxy potting",
      Warranty: "Limited lifetime warranty",
      LED: "Four-state standard (red, green, amber, and off)",
      Technologies: `P-300: Pyramid Series Proximity® Cards and Tags6
      \nP-300-H: Pyramid + certain HID® 125-kHz Proximity protocols7
      \nP-300-A: Pyramid + certain AWID® 125-kHz Proximity protocols8
      \nP-300-H-A: Pyramid + certain HID® 125-kHz Proximity protocols + certain AWID® 125-kHz Proximity protocol protocols + certain AWID® 125-kHz Proximity protocol`,
    },
    Image: product1,
  },
  {
    id: 2,
    Title: "DENALI MULLION-MOUNT READER & KEYPAD",
    ShortDecription: "Pyramid Series Proximity® from Farpointe Data sets",
    KeyFeatures: [
      "Unique Security Features",
      "Energy Conservation Technology",
      "Read Range Boosting Technology",
      "Energy Conservation Technology",
      "Lifetime Warrantee",
    ],
    Price: 500,
    Description:
      "Pyramid Series Proximity® from Farpointe Data sets the electronic security benchmark for 125-kHz proximity readers, cards, and tags. Based upon proven contactless digital radio frequency identification (RFID) technology, Pyramid readers interface with a wide range of electronic access control systems by complying with the Wiegand communication protocol. They offer value-add features such as MAXSecure™ and fleaPower™, and can be ordered to support several proximity card and tag technologies. Additionally, Pyramid cards and tags are passive devices, eliminate maintenance by requiring no battery, and can be ordered to support several proximity reader technologies.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "P-620",
      Technology: "Proximity",
      Frequency: "125 kHz",
      Mounting:
        "Mullions, including metal door and window frames, as well as flat surfaces",
      Dimensions: `1.7" W × 3.2" H × 0.7" D (43 mm × 81 mm × 18 mm)`,
      AreaDimensions: `0.9" W × 0.35" H (22.86 mm × 8.89 mm) with corner radius of 0.06" (1.5 mm)`,
      Weight: "4 oz (113 g)",
      Certifications: "FCC, ICC, CE, C-Tick, UL Standard 2941",
      IpCode2: "IP67",
      Voltage3: "+5 - 16 VDC",
      CurrentDraw: "70 mA typical, 110 mA peak @ 12 VDC",
      ReadRange4: "Up to 5 inches (126 mm)",
      Cabling5: `24 AWG minimum, multiconductor stranded with an overall foil shield`,
      Interface: `Wiegand (26-bit and custom formats), ABA Track II magnetic stripe (clock and data) or OSDP6`,
      OperatingTemperature: `–40° F to 149° F (–40° C to +65° C)`,
      ColorOptions: `Black and a white snap-on cover included standard`,
      AudioTone: "Beeper included standard",
      IndoorOutdoorInstallation:
        "Electronics sealed in weather- and tamper-resistant epoxy potting",
      Warranty: "Limited lifetime warranty",
      LED: "Four-state standard (red, green, amber, and off)",
      Technologies: `P-300: Pyramid Series Proximity® Cards and Tags6
      P-300-H: Pyramid + certain HID® 125-kHz Proximity protocols7
      P-300-A: Pyramid + certain AWID® 125-kHz Proximity protocols8
      P-300-H-A: Pyramid + certain HID® 125-kHz Proximity protocols + certain AWID® 125-kHz Proximity protocol`,
    },
    Image: product2,
  },
  {
    id: 3,
    Title: "CASCADE PROXIMITY READER P-500",
    ShortDecription: "Pyramid Series Proximity® P/N: 01105-001 ∙ Rev. 06.19",
    KeyFeatures: [
      "Unique Security Features",
      "Energy Conservation Technology",
      "Read Range Boosting Technology",
      "Energy Conservation Technology",
      "Lifetime Warrantee",
    ],
    Price: 500,
    Description:
      "Pyramid Series Proximity® from Farpointe Data sets the electronic security benchmark for 125-kHz proximity readers, cards, and tags. Based upon proven contactless digital radio frequency identification (RFID) technology, Pyramid readers interface with a wide range of electronic access control systems by complying with the Wiegand communication protocol. They offer value-add features such as MAXSecure™ and fleaPower™, and can be ordered to support several proximity card and tag technologies. Additionally, Pyramid cards and tags are passive devices, eliminate maintenance by requiring no battery, and can be ordered to support several proximity reader technologies.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "P-500",
      Technology: "Proximity",
      Frequency: "125 kHz",
      Mounting:
        "Metal or plastic US single-gang wall box, as well as flat surfaces",
      Dimensions: `1.7" W × 3.2" H × 0.7" D (43 mm × 81 mm × 18 mm)`,
      AreaDimensions: `0.9" W × 0.35" H (22.86 mm × 8.89 mm) with corner radius of 0.06" (1.5 mm)`,
      Weight: "3 oz (85 g)",
      Certifications: "FCC, ICC, CE, C-Tick, UL Standard 2941",
      IpCode2: "IP67",
      Voltage3: "+5 - 16 VDC",
      CurrentDraw: "35 mA typical, 75 mA peak @ 12 VDC",
      ReadRange4: "Up to 8 inches (202 mm)",
      Cabling5: `24 AWG minimum, multiconductor stranded with an overall foil shield`,
      Interface: `Wiegand (26-bit and custom formats), ABA Track II magnetic stripe (clock and data) or OSDP6`,
      OperatingTemperature: `–40° F to 149° F (–40° C to +65° C)`,
      ColorOptions: `Black and a white snap-on cover included standard`,
      AudioTone: "Beeper included standard",
      IndoorOutdoorInstallation:
        "Electronics sealed in weather- and tamper-resistant epoxy potting",
      Warranty: "Limited lifetime warranty",
      LED: "Four-state standard (red, green, amber, and off)",
      Technologies: `P-300: Pyramid Series Proximity® Cards and Tags6
      P-300-H: Pyramid + certain HID® 125-kHz Proximity protocols7
      P-300-A: Pyramid + certain AWID® 125-kHz Proximity protocols8
      P-300-H-A: Pyramid + certain HID® 125-kHz Proximity protocols + certain AWID® 125-kHz Proximity protocol`,
    },
    Image: product3,
  },
  {
    id: 4,
    Title: "CASCADE PROXIMITY READER P-300",
    ShortDecription: "Pyramid Series Proximity® P/N: 01105-001 ∙ Rev. 06.19",
    KeyFeatures: [
      "Unique Security Features",
      "Energy Conservation Technology",
      "Read Range Boosting Technology",
      "Energy Conservation Technology",
      "Lifetime Warrantee",
    ],
    Price: 500,
    Description:
      "Pyramid Series Proximity® from Farpointe Data sets the electronic security benchmark for 125-kHz proximity readers, cards, and tags. Based upon proven contactless digital radio frequency identification (RFID) technology, Pyramid readers interface with a wide range of electronic access control systems by complying with the Wiegand communication protocol. They offer value-add features such as MAXSecure™ and fleaPower™, and can be ordered to support several proximity card and tag technologies. Additionally, Pyramid cards and tags are passive devices, eliminate maintenance by requiring no battery, and can be ordered to support several proximity reader technologies.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "P-300",
      Technology: "Proximity",
      Frequency: "125 kHz",
      Mounting:
        "Mullions, including metal door and window frames, as well as flat surfaces",
      Dimensions: `1.7" W × 3.2" H × 0.7" D (43 mm × 81 mm × 18 mm)`,
      AreaDimensions: `0.9" W × 0.35" H (22.86 mm × 8.89 mm) with corner radius of 0.06" (1.5 mm)`,
      Weight: "3 oz (85 g)",
      Certifications: "FCC, ICC, CE, C-Tick, UL Standard 2941",
      IpCode2: "IP67",
      Voltage3: "+5 - 16 VDC",
      CurrentDraw: "30 mA typical, 75 mA peak @ 12 VDC",
      ReadRange4: "Up to 5 inches (126 mm)",
      Cabling5: `24 AWG minimum, multiconductor stranded with an overall foil shield`,
      Interface: `Wiegand ABA Track II`,
      OperatingTemperature: `–40° F to 149° F (–40° C to +65° C)`,
      ColorOptions: `Black and a white snap-on cover included standard`,
      AudioTone: "Beeper included standard",
      IndoorOutdoorInstallation:
        "Electronics sealed in weather- and tamper-resistant epoxy potting",
      Warranty: "Limited lifetime warranty",
      LED: "Four-state standard (red, green, amber, and off)",
      Technologies: `P-300: Pyramid Series Proximity® Cards and Tags6
      P-300-H: Pyramid + certain HID® 125-kHz Proximity protocols7
      P-300-A: Pyramid + certain AWID® 125-kHz Proximity protocols8
      P-300-H-A: Pyramid + certain HID® 125-kHz Proximity protocols + certain AWID® 125-kHz Proximity protocol`,
    },
    Image: product4,
  },
  {
    id: 5,
    Title: "MI-RD-M",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5017",
    KeyFeatures: [
      "Secure Smart Card Technology",
      "Modern LED design for a premium look",
      "Fully potted and functional at extreme temperatures",
      "Mullion mounted",
      "Sicunet card and credentials work with all readers",
      "Sounder option (Wire Connection)",
      "18″ Pigtail connection",
      "5-year hardware warranty",
    ],
    Price: 500,
    Description:
      "MIFARE is the NXP Semiconductors owned trademark of a series of integrated circuit (IC) chips used in contactless smart cards. Mi-RD-M conforms to the ISO/IEC 14443 Type A 13.56 MHz contactless smart card standard. It uses AES and DES/Triple- DES encryption.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "",
      Frequency: "13.56 MHz",
      CardType: "Mifare",
      OperatingVoltage: "9 - 24 VDC",
      StandbyCurrent: "<25mA",
      ReadRange: "12VDC @ 4Amp",
      OperatingTemperature: `–40° F to -140° F`,
      EnclosureSize: `4” x 1.88 x 0.75 in`,
      Protection: "Potted – IP 66",
      Humidity: "10% to 95% RH",
      Format:
        "32 bits Wiegand – Designed to decode credentials for Mi an ET Series Readers",
      Parts: "",
      Proxcard: "Mi-ISO-Crd-25",
      FOB: "Mi-Fob-25",
    },
    Image: product5,
  },
  {
    id: 6,
    Title: "ET 10/10L",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Sicunets Automatic Credential Issuance “ACI” seamlessly issues and",
      "Multiple technologies in a single reader: Proximity, Smart, NFC & BLE",
      "OSDP Secure Channel and remote firmware upgradability",
      "FSK and ASK legacy proximity credentials support for seamless transitions",
      "Designed to meet the harshest indoor and outdoor installation environments",
      "MlFARE DESFire® EV1 & EV2 suppor",
      "OSDP Auto-Detec",
      "No transformers required You do not need to open or jester just present the phone within range ",
      "Models designated with “L” are long range 15ft and 25ft",
    ],
    Price: 500,
    Description:
      "Sicunet’s “Automated Credential Issuance” ACI and complete line of readers seamlessly allows administrators to send mobile credentials with “one click” invi­ tation. ACI is easy and reduces the Total Cost Of Ownership “TC0” by dramatically reduces the time managing credential data and issuance. Each module functions as a Server, Expansion panel or Elevator",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "ETS",
      Style: "Mullion",
      Environment: "In I Outdoor - IP55 Rated",
      Technologies: "13.56MHz & Mobile",
      Voltage: "106 mA average, 144 mA peak",
      Current: "5-16 V DC",
      TempRange:
        "ULTM tested -35° to 66°C and EN -40° to 70°C and 95% humidity",
      BLERange: `Standard 3" - 4"`,
      BLERange2: `Long ( U 15tt - 25tt )`,
      Dimensions: `5.1" x 3.25" x 0.71`,
      ReadRange: `EV1 : 1.5'', EV2: 2.5'', 125 KHz up to 4"`,
      Output: `	Wegand, OSDP`,
      EnclosureSize: `4” x 1.88 x 0.75 in`,
      Warranty: "Lifetime",
      Certifications:
        "FCC, IC, CE, UL 294, EN 302291, EN 301489, EN 300330, EN 50130-4, BIS IS 13252",
    },
    Image: product6,
  },
  {
    id: 7,
    Title: "ET 5",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Unique Security features",
      "Energy Conservation technology",
      "Read Range Boosting technology",
      "Energy Conservation Technology",
      "Lifetime warrantee",
    ],
    Price: 500,
    Description:
      "Sicunet’s “Automated Credential Issuance” ACI and complete line of readers seamlessly allows administrators to send mobile credentials with “one click” invi­ tation. ACI is easy and reduces the Total Cost Of Ownership “TC0” by dramatically reduces the time managing credential data and issuance. Each module functions as a Server, Expansion panel or Elevator",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "ET 5",
      Style: "Mullion",
      Environment: "In/Outdoor - IP55 Rated",
      Technologies: "13.56MHz & Mobile",
      Voltage: "5-16 V DC",
      Current: "106 mA average, 144 mA peak",
      TempRange:
        "ULTM tested -35° to 66°C and EN -40° to 70°C and 95% humidity",
      BLERange: `Standard 3" - 4"`,
      BLERange2: `Long ( U 15ft - 25ft )`,
      Dimensions: `5.1" x 3.25" x 0.71`,
      ReadRange: `EV1 : 1.5'', EV2: 2.5'', 125 KHz up to 4"`,
      Output: `	Wegand, OSDP`,
      EnclosureSize: `4” x 1.88 x 0.75 in`,
      Warranty: "Lifetime",
      Certifications:
        "FCC, IC, CE, UL 294, EN 302291, EN 301489, EN 300330, EN 50130-4, BIS IS 13252",
    },
    Image: product7,
  },
  {
    id: 8,
    Title: "ET 25L",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Sicunets Automatic Credential Issuance “ACI” seamlessly issues and",
      "Multiple technologies in a single reader: Proximity, Smart, NFC & BLE",
      "OSDP Secure Channel and remote firmware upgradability",
      "FSK and ASK legacy proximity credentials support for seamless transitions",
      "Designed to meet the harshest indoor and outdoor installation environments",
      "Tri-state LED light bar (red, green, amber) and audible beeper ",
      "No transformers required You do not need to open or jester just present the phone within range ",
      "Models designated with “L” are long range 15ft and 25ft",
    ],
    Price: 500,
    Description:
      "Sicunet’s “Automated Credential Issuance” ACI and complete line of readers seamlessly allows administrators to send mobile credentials with “one click” invi­ tation. ACI is easy and reduces the Total Cost Of Ownership “TC0” by dramatically reduces the time managing credential data and issuance. Each module functions as a Server, Expansion panel or Elevator",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "ETS",
      Style: "Single gang with keypad",
      Environment: "In/Outdoor - IP55 Rated",
      Technologies: "125 KHz & 13.56MHz & Mobile",
      Voltage: "118 mA average, 169 mA peak",
      Current: "5-16 V DC",
      TempRange:
        "ULTM tested -35° to 66°C and EN -40° to 70°C and 95% humidity",
      BLERange: `Standard 3" - 4"`,
      BLERange2: `Long ( U 15tt - 25tt )`,
      Dimensions: `5.1" x 3.25" x 0.71`,
      ReadRange: `EV1 : 1.5'', EV2: 2.5'', 125 KHz up to 4"`,
      Output: `	Wegand, OSDP`,
      Warranty: "Lifetime",
      Certifications:
        "FCC, IC, CE, UL 294, EN 302291, EN 301489, EN 300330, EN 50130-4, BIS IS 13252",
    },
    Image: product8,
  },
  {
    id: 9,
    Title: "ET 20L",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Sicunets Automatic Credential Issuance “ACI” seamlessly issues and",
      "Multiple technologies in a single reader: Proximity, Smart, NFC & BLE",
      "OSDP Secure Channel and remote firmware upgradability",
      "FSK and ASK legacy proximity credentials support for seamless transitions",
      "Designed to meet the harshest indoor and outdoor installation environments",
      "Tri-state LED light bar (red, green, amber) and audible beeper ",
      "No transformers required You do not need to open or jester just present the phone within range ",
      "Models designated with “L” are long range 15ft and 25ft",
    ],
    Price: 500,
    Description:
      "Sicunet’s “Automated Credential Issuance” ACI and complete line of readers seamlessly allows administrators to send mobile credentials with “one click” invi­ tation. ACI is easy and reduces the Total Cost Of Ownership “TC0” by dramatically reduces the time managing credential data and issuance. Each module functions as a Server, Expansion panel or Elevator",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "ETS",
      Style: "Single gang",
      Environment: "In/Outdoor - IP55 Rated",
      Technologies: "125 KHz & 13.56MHz & Mobile",
      Voltage: "118 mA average, 169 mA peak",
      Current: "5-16 V DC",
      TempRange:
        "ULTM tested -35° to 66°C and EN -40° to 70°C and 95% humidity",
      BLERange: `Standard 3" - 4"`,
      BLERange2: `Long ( U 15tt - 25tt )`,
      Dimensions: `5.1" x 3.25" x 0.71`,
      ReadRange: `EV1 : 1.5'', EV2: 2.5'', 125 KHz up to 4"`,
      Output: `	Wegand, OSDP`,
      Warranty: "Lifetime",
      Certifications:
        "FCC, IC, CE, UL 294, EN 302291, EN 301489, EN 300330, EN 50130-4, BIS IS 13252",
    },
    Image: product9,
  },
  {
    id: 10,
    Title: "125KHZ CREDENTIALS",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Laser printed FC and Card Number",
      "ISO Thickness – Blank printable back",
      "White with Sicunet logo",
      "Packaged in sequential order",
      "Quantity per pack 25 white cards",
      "Frequency 125 khz",
      "Card | Fob Type 213",
      "Bit type	26 bit credential",
    ],
    Price: 500,
    Description:
      "MIFARE is the NXP Semiconductors-owned trademark of a series of integrated circuit (IC) chips used in contactless smart cards. Our credentials conform to the ISO/IEC 14443 Type A 13.56 MHz contactless smart card standard utilizing AES and DES/Triple- DES encryption standards.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {},
    Image: product10,
  },
  {
    id: 11,
    Title: "13.56MHZ CREDENTIALS",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5017",
    KeyFeatures: [
      "Secure Smart Card Technology",
      "Laser printed FC and Card Number",
      "ISO Thickness – Blank printable back",
      "Works with S1-R and all mobile credential readers",
      "Quantity per pack	25 white cards",
      "Frequency 13.56 khz",
      "Card | Fob Type Mifare",
      "Bit type	32 bit credential",
    ],
    Price: 500,
    Description:
      "MIFARE is the NXP Semiconductors-owned trademark of a series of integrated circuit (IC) chips used in contactless smart cards. Our credentials conform to the ISO/IEC 14443 Type A 13.56 MHz contactless smart card standard utilizing AES and DES/Triple- DES encryption standards.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {},
    Image: product11,
  },
  {
    id: 12,
    Title: "RMC-1S",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5018",
    KeyFeatures: [
      "Manage one system or unlimited",
      "Secure out of network remote management of system",
      "Encrypted connection and data transfer. SSL,AES 256 and HTTPS",
      "Browser Managed – Does not require any additional software required",
      "Secure hyperlink allows for one click connection to system.",
      "All the data is managed and maintained at the edge",
      "Customer all the software and is not required to pay a monthly fee.",
      "Cache the username and password for easy access.",
      "Technology manages the system locally via the cloud so if the cloud is off-line the system can still be managed locally!",
    ],
    Price: 500,
    Description:
      "MIFARE is the NXP Semiconductors-owned trademark of a series of integrated circuit (IC) chips used in contactless smart cards. Our credentials conform to the ISO/IEC 14443 Type A 13.56 MHz contactless smart card standard utilizing AES and DES/Triple- DES encryption standards",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {},
    Image: product12,
  },
  {
    id: 13,
    Title: "12 VDC",
    ShortDecription: "2 AMP PoE SWITCH",
    KeyFeatures: [
      "International standard 48v, IEEE802.3AT/AF standarSupport 44v-57v wide voltage design",
      "RJ45 network output,12v 2amp output DC",
      "More compatible, high power, super stable, intelligent",
      "The intelligent automatic recognition is transmitted by the power of the PPD and the distance of 140-180 meters",
    ],
    Price: 500,
    Description:
      "MIFARE is the NXP Semiconductors-owned trademark of a series of integrated circuit (IC) chips used in contactless smart cards. Our credentials conform to the ISO/IEC 14443 Type A 13.56 MHz contactless smart card standard utilizing AES and DES/Triple- DES encryption standards",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      PartNumber: "POE-1DC",
      POE: "Power: 15.4W, Input Voltage: 44-57V, Output Voltage: DC 12V, Current Input: 12-35Watts",
      EIS: "Input: 1 input EJ45 port, Output: 1 output EJ45 port; 1 DC output port, Data transmitting rate: 10/100Mbps, Data transmitting distance: 100M",
      ProtocolStandard:
        "IEEE 802.3i 10BASE-T; IEEE 802.3u 10BASE-TX; IEEE 802.3x Flow Control, IEEE 802.3af Power over Ethernet",
      LED: "POE input power indicator light, DC output power indicator light",
      EnvironmentSpecification:
        "Operating temperature: 0-50°C; operating humidity: 85%, no condensation",
      Basic: `Power / Amps out of this product are controlled by the wattage of the network hardware and line due to distance and cable. "Watts / 12 volts" provides output load the hardware. Note: It is important to perform a load calculation of devices connected.`,
    },
    Image: product13,
  },
  {
    id: 14,
    Title: "SMART CREDENTIALS",
    ShortDecription: "SC-50H4/8  SC-50D4/8  SC-60H8",
    KeyFeatures: [],
    Price: 500,
    Description:
      "Sicunet offers a full line of durable and reliablesmart credentials with factory programming, including off-the-shelf and custom encryption keys, standard and unique bitformats, choice of facility codes, and ID ranges to tailorcredentials to meet your specific needs. Your secured badgeID data will be tracked upon request* to ensure that no cardsare duplicated in future orders. Full-color, custom printing isavailable by special request—fees apply.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      mainFeatures:
        "MIFARE DESFire EV2 Car, Dual Frequency Proximity + MIFARE_DESFire EV2 Card, MIFARE DESFire EV2 Keyfob",
      Memory: "4K (P/N SC-50D4), 8K (P/N SC-50D8)",
      Security_Level: "EAL 5+",
      Dimension: `3.37" x 2.13" x 0.03" (85.6 mm x 54.0 mm x 0.8 mm) | 1.57" x 1.22" x 0.19" (39.9 mm x 31.0 mm x 4.9 mm)`,
      Frequency: "125 kHz + 13.56 MHz | 13.56 MHz",
      Read_Range: `Up to 3" | Up to 1"`,
      Slot_Punch: "Vertical or Horizontal  |  N/A",
      Storage_Operating_Temps:
        "-50° to 160°F, 10° to 71°C | -13°C to +176°F, -25° to +80°C",
      Material: "PVC + PET | Polycarbonate",
      LEAF_Compatibility:
        "LEAF Si Enabled standard, LEAF Cc Enabled by request",
      Number_of_Applications: "No Limitation",
      Colors: "White | Black with White Center",
      Warranty: "Lifetime | 3 Years",
    },
    Image: product14,
  },
  {
    id: 15,
    Title: "PSC-1",
    ShortDecription: "STANDARD LIGHT PROXIMITY CARD",
    KeyFeatures: [
      "MAXSecure: Unique Security Feature",
      "Lifetime Warranty",
      "Wigeand: Lifetime Warranty",
      "Slot-Punch: Standard Vertical Slot",
      "Contactless: Upto 8 in (202mm)",
    ],
    Price: 500,
    Description:
      "Pyramid Series Proximity’ *rom Farpointe Data sets the electronic security benchmark for 25-kHz Droxi mity readers, cards, and tags. Based upon proven contactless digital radio freauency ›dent›fication (RFID) tech nology, Pyramid readers interface with a wide range of electronic access control systems by comDlying with the Wiegand communication protocol. They older value-add features such as MAXSecure’” and fleaPower’”, and can be ordered to suDport several proximity card and tag technologies. Additionally, Pyramid cards and tags are Dassive devices, e iminate ma ntenance by requiring no battery, and can be ordered to support several proximity reader technologies.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Technology: "Proximity",
      Frequency: "Excitation (125 KHz)",
      Operation: "Passive (no battery)",
      Type: "Clamshell card",
      Formats:
        "Wiegand(26-bit & custom formats) & ABA Track || magnetic stripe(clock & data)",
      Material: "ABS",
      Color: "Off-White",
      SlotPunch: "Standard, Vertical slot",
      Marking: "Date code & ID",
      Imaging:
        "Use a glossy adhesive overlay for color dye sublimation printing or print directly on card",
      ReadRange: `Upto 8 inches (202mm)`,
      TechnologiesSupported: `PSC-1: Pyramid Series Proximity supported, PSC-1-H: Certain HID 125-KHz Proximity protocols supported, PSC-1-A: Certain AWID 125-KHz Proximity protocols supported`,
      Options:
        "Custom printing of company logos, URL, telephone number and more ",
      Warranty: "Limited lifetime warranty",
      OemDimension: `0.9"W x 0.35"H (22.86mm x 8.89mm) with corner radius of 0.06"(1.5mm)`,
      Dimension: `2.2"W x 3.4"H x 0.6"D(56mm x 86mm x 1.5mm)`,
      Weight: "0.3 oz (9g)",
      OperatingTemperature: "-35°F to 122°F (-37°C to +50°C)",
      Humidity: "0-95% non-condensing",
    },
    Image: product15,
  },
  {
    id: 16,
    Title: "BP-4D",
    ShortDecription: "FOUR DOOR RETROFIT SDOC-5016",
    KeyFeatures: [
      "Backplane is pre-configured with mounting holes for the following panels",
      "E3 Panels by Linear (ES, EL, and EXN’s) S2 – 50 / 5000 TruePortal",
      "Supports “Single” or “Double” stacking of panels in retrofit housing",
      "Each module functions as a Server, Expansion panel or Elevator",
      "e3 version – restoral of database from legacy system to the retrofit system",
      "4 / 8 Readers (In/Out)",
      "4 / 8 Relays (Lock/Aux)",
      "6 / 12 Inputs (REX, DPS, Aux) Global",
      "5-Year hardware warranty",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "AC-BPCORP128",
      Processor: "Quad Core Cortex, 1.2 GHz",
      Memory: "Gig NAND Non-volatile, USB backup option",
      OperatingSystem: "Embedded Linux",
      Transactions: "> 60 per second",
      SystemPower: "12VDC@2.5A 2 Door or 12VDC@5A for 4 Door",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      Ethernet: `10 / 100 Base Auto sense`,
      Power: "400mA @ 12V",
      Readers: `4 / 8 Total, One In & One Out Reader for Each Door`,
      ReaderPower: "300mA @ 12v Max per reader",
      REX: "2 / 4 Total, One for Each Door",
      DoorInput: "2 / 4 Total, One for Each Door",
      AuxiliaryInput: "2 / 4 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerDigitalInput: "Yes",
      DoorLockOutput: "2/4 Form C Relay, 24V @2.0A",
      AuxiliaryOutput: "2/4 Form C Relay, 24V @2.0A",
    },
    Image: product16,
  },
  {
    id: 17,
    Title: "ACS Mini PC",
    ShortDecription: "PART # CORPPLUSS SDOC-5017",
    KeyFeatures: [
      "Server Level Mini Machine",
      "Out of the box ready, browser managed",
      "Linux 64bit operating system",
      "Apache Web server – Up to 25 concurrent administrators",
      "Optional Data back up FTP and USB",
      "Mobile Manager Enabled",
      "Remote Management Connector Enabled",
      "3-Year hardware warranty",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "CorpPlusS",
      Processor: "Intel 4 Core Processor",
      Memory: "4GB DDR3 & 64GB Solid State Drive",
      OperatingSystem: "Linux / 64 Bit",
      Transactions: "> 100 per second",
      SystemPower: "12VDC@ 4Amp",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `7.28 x 7.28 x 0.47in (185 x 185 x 25mm)`,
      Power: "Plug in included",
      BatteryBackup: "N/A",
      RecommendedBatteryBackup: "UPS",
      DoorClient: "128 128",
      CorpPlusU:
        "Software License for 256 doors and 128 clients | Upgrade Software License from 256 to 360 doors",
    },
    Image: product17,
  },
  {
    id: 18,
    Title: "8DXLPL",
    ShortDecription:
      "INTEGRATED POWER FOR ALL THE PANELS, READERS AND LOCKS: 8 DOOR PANEL",
    KeyFeatures: [
      "Contains two 4 door modules in housing",
      "Each module functions as a Server, Expansion panel or Elevator",
      "Integrated 150-Watt power supply for complete system power",
      "Wedge – Open enclosure design provides easy access to wiring",
      "Lid Removal disconnect pins for fast access to hardware",
      "Tie downs support dual sided strapping for wire management",
      "ETL – UL 294 (Pending)",
      "5-Year hardware warranty",
      "2 Power boards Located on Left and Right sides",
      "IEC Cord 5’ Included",
      "8 Auto Resetting Fused Outputs",
      "Isolated power for readers & controller 12VDC or 350ml for 24 VDC",
      "Selectable 12 or 24 VDC per output",
      "LED status for voltage, power short and fire input triggered",
      "Power supervision – log and action",
      "Battery Backup (Battery not included)",
      "Battery Backup (Battery not included)",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability.",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "8DXLPL",
      OperatingSystem: "	Embedded Linux",
      Transactions: "60 per second",
      PowerRequirements: "115VAC | Standard Wall plug in outlet",
      Processor: "Intel 4 Core Processor",
      Memory: "4GB DDR3 & 64GB Solid State Drive",
      SystemPower: "12VDC@ 4Amp",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `14.75” x 14.75” x 7.00” | (375mm x 375mm x 178mm)`,
      IntegratedPower:
        "150 Watt – IEC Cord | Controller, Readers & 12V and 24V DC for Door Locks",
      SelectableLockPower: "12 VDC 700mA, 24 VDC 350mA",
      BatteryBackup: "Controllers, Readers, & Door Lock",
      RecommendedBatteryBackup:
        "12V, 7AH, Sealed Lead Acid with 0.187 inch terminals",
      RJ485Ethernet:
        "Requires two ( 2 ) TCIP Connections and IP addresses / 1 per module",
      Readers: "16 Readers, 8 In & 8 Out Reader for Each Door",
      ReaderPower:
        "300mA @ 12V Max per Reader 1000mA @ 12V Max per 4 door panel",
      ReaderPowerInput: "8 Total, One for Each Door",
      DoorPositionInput: "8 Total, One for Each Door",
      AuxiliaryInput: "8 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerFaultDigitalInput: "Yes",
      DoorLockOutput: "8 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "8 Form C Relay, 24V @ 2.0A",
    },
    Image: product18,
  },
  {
    id: 19,
    Title: "4DMPL",
    ShortDecription:
      "FOUR DOOR CONTROLLER SDOC-5016  POWER PACKAGE INCLUDES 12 AND 24 VOLTS OUTPUT FOR LOCKS",
    KeyFeatures: [
      "Each module functions as a Server, Expansion panel or Elevator",
      "Configured for 128 Doors | 64 Panels",
      "Out of the box ready browser managed",
      "Embedded Linux operating system",
      "4 Doors / 8 Readers (In/Out)",
      "8 Relays (Lock/Aux)",
      "12 Inputs (REX, DPS, Aux) Global",
      "5-year warranty",
      "IEC Cord 5’ Included",
      "4 Auto Resetting Fused Outputs",
      "Selectable 12 or 24 VDC per output",
      "750ml 12VDC or 350ml for 24 VDC",
      "LED status for voltage, power short",
      "Fire Drop Input",
      "Power supervision – log and action",
      "Battery Backup (Battery not included)",
      "Battery backup for 12 and 24 volts.",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "4DM",
      Processor: "Quad Core, 1.5 GHz",
      Memory: "1GB DDR3 & 8GB eMMc",
      OperatingSystem: "Embedded Linux",
      Transactions: "> 60 per second",
      SystemPowerRequirements: "115VAC @ 1.5A",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `14.25 x 14.25 x 3.75in | (362mm x 362mm x 95mm)`,
      InternalPowerSupply:
        "Controller, Readers & 12V and 24V DC for Door Locks",
      LockOutputPower: "12 VDC 700 MLS, 24VDC 350 MLS",
      BatteryBackup: "Controllers, Readers, & Door Lock",
      RecommendedBatteryBackup:
        "12V, 7AH, Sealed Lead Acid with 0.187 inch terminals",
      StandbyPowerRequirements: "450mA @ 12V",
      Readers: "8 Total, One In & One Out Reader for Each Door",
      ReaderPower: "300mA @ 12V Max per Reader 1000mA @ 12V Max per system",
      REXInput: "4 Total, One for Each Door",
      DoorPositionInput: "4 Total, One for Each Door",
      AuxiliaryInput: "4 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerFaultDigitalInput: "Yes",
      DoorLockOutput: "4 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "4 Form C Relay, 24V @ 2.0A",
    },
    Image: product19,
  },
  {
    id: 20,
    Title: "4DM",
    ShortDecription:
      "FOUR DOOR CONTROLLER SDOC-5003 INTEGRATED POWER FOR THE CONTROLLER AND READERS WITH BATTERY BACK UP",
    KeyFeatures: [
      "Each module functions as a Server, Expansion panel or Elevator",
      "Out of the box ready browser managed",
      "Embedded Linux operating system",
      "8 Relays (Lock/Aux)",
      "12 Inputs (REX, DPS, Aux) Global",
      "UL Listed – 294 Series 7",
      "5-year warranty",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "4DM",
      Processor: "Quad Core, 1.5 GHz",
      Memory: "1GB DDR3 & 8GB eMMc",
      OperatingSystem: "Embedded Linux",
      Transactions: "> 60 per second",
      SystemPowerRequirements: "115VAC @ 1.5A",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `14.25 x 14.25 x 3.75in | (362mm x 362mm x 95mm)`,
      InternalPowerSupply:
        "Controller, Readers & 12V and 24V DC for Door Locks",
      LockOutputPower: "12 VDC 700 MLS, 24VDC 350 MLS",
      BatteryBackup: "Controllers, Readers, & Door Lock",
      RecommendedBatteryBackup:
        "12V, 7AH, Sealed Lead Acid with 0.187 inch terminals",
      StandbyPowerRequirements: "450mA @ 12V",
      Readers: "8 Total, One In & One Out Reader for Each Door",
      ReaderPower: "300mA @ 12V Max per Reader 1000mA @ 12V Max per system",
      REXInput: "4 Total, One for Each Door",
      DoorPositionInput: "4 Total, One for Each Door",
      AuxiliaryInput: "4 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerFaultDigitalInput: "Yes",
      DoorLockOutput: "4 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "4 Form C Relay, 24V @ 2.0A",
    },
    Image: product19,
  },
  {
    id: 21,
    Title: "2DM",
    ShortDecription:
      "TWO DOOR CONTROLLER SDOC-5016 INTEGRATED POWER FOR PANEL AND READERS WITH BATTERY BACK UP",
    KeyFeatures: [
      "Each module functions as a Server, Expansion panel or Elevator",
      "Out of the box ready browser managed",
      "Embedded Linux operating system",
      "2 Doors – 4 Readers (In/Out)",
      "2 Relays (Lock/Aux)",
      "3 Inputs (REX, DPS, Aux) Global",
      "UL Listed – 294 Series 7",
      "5-year hardware warranty",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "2DMP",
      OperatingSystem: "Embedded Linux",
      Transactions: "> 60 per second",
      SystemPowerRequirements: "115VAC @ 1.5A",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `14.25 x 14.25 x 3.75in | (362mm x 362mm x 95mm)`,
      InternalPowerSupply: "Controller, Readers Only",
      LockOutputPower: "N/A",
      BatteryBackup: "Controllers, Readers, & Door Lock",
      RecommendedBatteryBackup:
        "12V, 7AH, Sealed Lead Acid with 0.187 inch terminals",
      StandbyPowerRequirements: "450mA @ 12V",
      Readers: "4 Total, One In & One Out Reader for Each Door",
      ReaderPower: "300mA @ 12V Max per Reader 1000mA @ 12V Max per system",
      REXInput: "2 Total, One for Each Door",
      DoorPositionInput: "2 Total, One for Each Door",
      AuxiliaryInput: "2 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerFaultDigitalInput: "Yes",
      DoorLockOutput: "1 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "1 Form C Relay, 24V @ 2.0A",
    },
    Image: product19,
  },
  {
    id: 22,
    Title: "2DML",
    ShortDecription:
      "TWO DOOR CONTROLLER SDOC-5016 POWER PACKAGE INCLUDES 12 AND 24 VOLTS OUTPUT FOR LOCKS",
    KeyFeatures: [
      "Each module functions as a Server, Expansion panel or Elevator",
      "Configured for 128 Doors | 64 Panels",
      "Out of the box ready browser managed",
      "Embedded Linux operating system",
      "2 Doors – 4 Readers (In/Out)",
      "4 Relays (Lock/Aux)",
      "6 Inputs (REX, DPS, Aux) Global",
      "5-year hardware warranty",
      "IEC Cord 5’ Included",
      "4 Auto Resetting Fused Outputs",
      "Selectable 12 or 24 VDC per output",
      "750ml 12VDC or 350ml for 24 VDC",
      "Isolated power for readers & controller",
      "IEC Cord 5’ Included",
      "Fire Drop Input",
      "Power supervision– log and action",
      "Fire Drop Input | Battery backup for 12 and 24 volts",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Model: "2DMP",
      OperatingSystem: "Embedded Linux",
      Transactions: "> 60 per second",
      SystemPowerRequirements: "115VAC @ 1.5A",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      EnclosureSize: `14.25 x 14.25 x 3.75in | (362mm x 362mm x 95mm)`,
      InternalPowerSupply: "Controller, Readers Only",
      LockOutputPower: "N/A",
      BatteryBackup: "Controllers, Readers, & Door Lock",
      RecommendedBatteryBackup:
        "12V, 7AH, Sealed Lead Acid with 0.187 inch terminals",
      StandbyPowerRequirements: "450mA @ 12V",
      Readers: "4 Total, One In & One Out Reader for Each Door",
      ReaderPower: "300mA @ 12V Max per Reader 1000mA @ 12V Max per system",
      REXInput: "2 Total, One for Each Door",
      DoorPositionInput: "2 Total, One for Each Door",
      AuxiliaryInput: "2 Total, General Purpose",
      TamperDigitalInput: "Yes",
      PowerFaultDigitalInput: "Yes",
      DoorLockOutput: "1 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "1 Form C Relay, 24V @ 2.0A",
    },
    Image: product19,
  },
  {
    id: 23,
    Title: "1DOOR NEPTUNE ",
    ShortDecription: "ACCESS CONTROL SYSTEM SDOC-5002",
    KeyFeatures: [
      "Each module functions as a Server, Elevator",
      "Out of the box ready browser managed",
      "Embedded Linux operating system",
      "2 Readers (In/Out)",
      "5-year hardware warranty",
      "2 Relays (Lock/Aux)",
      "3 Inputs (REX, DPS, Aux)",
      "Global UL Listed – 294 Series 7",
      "Includes Mounting Bracket",
    ],
    Price: 500,
    Description:
      "Neptune is a comprehensive embedded OS and browser-managed access control system. Proven with tens of thousands of installations, Neptune systems are fast and easy to set-up, provide enhanced features and are scalability",
    Product: `At the heart of Sicunet's access control platform is an embedded browser managed physical access control system (PACS) eco system that can scale from one to 512 doors, with up to 100,000 users with one or many sites On- premise and Cloud management. Our architecture is better than our competitors, as each panel contains a full system operating system with a web server. Any piece of hardware can be individually managed as a separate system or configured so that they talk with each other as one large system. Each panel has the ability to morph into any function within the system. Every panel has a full copy of the database for database reduncency. The bulk of our competitors have specific software and hardware for this capability. This design ensure your system should it need service that it is it can be serviced faster ans at a lower cost than our competitors.
    Browser Managed provides the greatest flexibility for programming.`,
    Specification: {
      Processor: "Quad Core Cortex, 1.5 GHz",
      Memory: "1GB DDR3 & 8GB eMMc",
      OperatingSystem: "Embedded Linux",
      Transactions: "Embedded Linux",
      PowerRequirements: "Regulated 12VDC @ 2A, Class 2 (not supplied)",
      OperatingTemperature: "50°F to 95°F (10°C to 35°C)",
      StandbyPowerRequirements: "350mA @ 12V",
      NumOfDoors: "2 Total, One In & One Reader",
      Readers: "2 Total, One In & One Reader",
      ReaderPower: "300mA @ 12V Max per Reader 600mA @ 12V Max per system",
      REXInput: "1",
      DoorPositionInput: "1",
      AuxiliaryInput: "1",
      TamperDigitalInput: "N/A",
      PowerFaultDigitalInput: "N/A",
      DoorLockOutput: "1 Form C Relay, 24V @ 2.0A",
      AuxiliaryOutput: "1 Form C Relay, 24V @ 1.0A",
    },
    Image: product23,
  },
];
